import React, { useState, useEffect } from "react"
import styles from "./index.module.scss"
import Menubar from "../components/menubar/menubarEng"
import Sidebar from "../components/sidebar/sidebar"
import SidebarExt from "../components/sidebar/sidebarExt"
import ProductFoursome from "../components/productFoursome/productFoursome"
import Footer from "../components/footer/footerEng"
import FormComp from "../components/formComp/formCompEng"
import Year40 from "../img/40yil.svg"
import Carousel from "react-bootstrap/Carousel"
import car_1 from "../img/carou1.jpg"
import car_2 from "../img/carou2.jpg"
import car_3 from "../img/carou3.jpg"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons"

const IndexEng = () => {
  const [wSize, setWSize] = useState("default")
  var myFunc = function() {
    var y = window.innerWidth
    if (y <= 577) {
      setWSize("none")
    } else {
      setWSize("default")
    }
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      myFunc()
    }
  })
  const data = useStaticQuery(graphql`
    query {
      project1: file(relativePath: { eq: "nyKonutuKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project2: file(relativePath: { eq: "kirklareliKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project3: file(relativePath: { eq: "onVillaKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project4: file(relativePath: { eq: "syKonutuKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)
  return (
    <div className={styles.index}>
      <div className={styles.leftE}></div>
      <div className={styles.centerSite}>
        <Sidebar />
        <SidebarExt />
        <Carousel
          id="mainpageCar"
          className={styles.mainCarWindow}
          nextIcon={
            <FontAwesomeIcon
              icon={faLongArrowAltRight}
              size="2x"
              alt="forward"
            />
          }
          prevIcon={
            <FontAwesomeIcon
              icon={faLongArrowAltLeft}
              size="2x"
              alt="backward"
            />
          }
          controls={true}
          indicators={false}
          fade={true}
          interval="3000"
        >
          <Carousel.Item>
            <img src={car_1} alt="car1" className={styles.carImage} />
            <Carousel.Caption className={styles.textContainer}>
              <p className={styles.header}>
                FROM LINES <br />
                <span>TO LIVING SPACES</span>
              </p>
              <div className={styles.horizontalLine} />

              <p className={styles.paraf}>
                Atia offers spaces used properly and prioritize life quality and
                comfort of everyone who desire to be in distinctive ergonomic
                living spaces with a character of its own.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={car_2} alt="car2" className={styles.carImage} />
            <Carousel.Caption className={styles.textContainer}>
              <p className={styles.header}>
                FROM QUALITY <br />
                <span>TO DETAIL</span>
              </p>
              <div className={styles.horizontalLine} />

              <p className={styles.paraf}>
                Prominent with its application quality and innovative and
                different solutions to each and every detail, Atia Wood also
                offers interior design, project, application, and consultancy
                services thanks to its interior designers and technical staff.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={car_3} alt="car3" className={styles.carImage} />
            <Carousel.Caption className={styles.textContainer}>
              <p className={styles.header}>
                EXPERIENCE <br />
                <span>AND EXPERTISE</span>
              </p>
              <div className={styles.horizontalLine} />

              <p className={styles.paraf}>
                The brand Atia with 36 years of experience and knowledge behind
                it, starting from apprenticeship, has reached its high level of
                quality today.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <Menubar />
        <ProductFoursome
          className={styles.productsContainerIndex}
          displayProp={wSize}
          /* Product 1-1 */
          iSrc1={data.project1.childImageSharp.fluid.src}
          info1={`NY \nHOUSING`}
          iLink1={`/projects/nyKonutuEng`}
          /* Product 1-2 */
          iSrc2={data.project2.childImageSharp.fluid.src}
          info2={`KIRKLARELI GOVERNORSHIP \nASSEMBLY HALL`}
          iLink2={`/projects/kirklareliValilikEng`}
          /* Product 1-3 */
          iSrc3={data.project3.childImageSharp.fluid.src}
          info3={`DATÇA - BURGAZ \nVILLAS`}
          iLink3={`/projects/datcaBurgazVillaEng`}
          /* Product 1-4 */
          iSrc4={data.project4.childImageSharp.fluid.src}
          info4={`SY \nHOUSING`}
          iLink4={`/projects/syKonutuEng`}
        />
        <div className={styles.year36Container}>
          <img src={Year40} alt="40 year" />
        </div>
        <FormComp displayValue={wSize} />
        <Footer displayVal={wSize} />
      </div>
      <div className={styles.rightE}></div>
    </div>
  )
}

export default IndexEng
